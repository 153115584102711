<template>
  <div>
    <h1 style="text-align: center;">Server Side Rendering</h1>
    <Builder :user-mode="false" :builderData="builderData" v-if="builderData" @ready="downloadHQ" ref="builder"/>
    <Merger :pages="pages" v-if="pages" />
  </div>
</template>
<script>
import { apiCall } from '@/client.js';
import Builder from '@/pages/Admin/Builder.vue';
import Merger from '@/components/Merger.vue';
export default {
    name: 'SSR',
    components: {
        Builder,
        Merger
    },
    data() {
        return {
            builderData: null,
            pages: null
        }
    },

    methods: {
        downloadHQ(){
            this.$refs.builder.exportHQPng();
        },

        async getOrder(orderId){
            const result = await apiCall('GET', '/Order/Draft/' + orderId);
            if(result.status == 200){
                return result.data;
            }

            return null;
        },
        
        async getPage(orderId, pageId){
            const result = await apiCall('GET', '/Order/Draft/' + orderId + '/Page/' + pageId);
            if(result.status == 200){
                return result.data;
            }

            return null;
        },

        async getPageData(orderId, pageId){
            const order = await this.getOrder(orderId); // all pages

            const result = await this.getPage(orderId, pageId);
            if(result != null){
                var tempBuilderData = {
                    layout: result,
                    product: result.order.product,
                    ssr: true,
                }

                /* dorsino handling:
                1. understand if we will have a dorsino: if product.productVariants for this page numbers has dorsino > 0 mm we have it, otherwise we dont (no further action required)
                2. adjust left/right margins according to it (it depends by Layout tipe: 'StartCoverFront','StartCoverBack','EndCoverFront','EndCoverBack','Page','Spine')
                */
                const orderPageNo = order.pages.length;
                const productVariant = result.order.product.productVariants.find(pv => pv.pages == orderPageNo);

                if(!productVariant){
                    console.warn('No product variant found for ' + orderPageNo + ' pages, skipping');
                } else {
                    console.log('Product variant found', productVariant);
                    // 
                    /* adjust margins:
                        for external cover (Front) we have front the final layout as following: EndCoverFront, Spine, StartCoverFront; 
                        for external cover (Back) we have front the final layout as following: EndCoverBack, Spine, StartCoverBack
                        the adiacent pages sides must have no margin between them
                    */
                   if(productVariant.dorsino > 0){
                        switch(result.layout.type){
                            case 'StartCoverFront':
                                tempBuilderData.product.marginLeft = 0;
                                break;
                            case 'StartCoverBack':
                                tempBuilderData.product.marginRight = 0;
                                break;
                            case 'EndCoverFront':
                                tempBuilderData.product.marginRight = 0;
                                break;
                            case 'EndCoverBack':
                                tempBuilderData.product.marginLeft = 0;
                                break;
                            case 'Spine':
                                tempBuilderData.product.marginLeft = 0;
                                tempBuilderData.product.marginRight = 0;
                                tempBuilderData.product.width = productVariant.dorsino;
                                break;
                        }
                    }
                }

                this.builderData = tempBuilderData;
            }
        },

        async getPagesData(orderId, pageIds){
            const pagesData = await Promise.all(pageIds.map(async pageId => {
                return await this.getPage(orderId, pageId);
            }));

            this.pages = pagesData;
            console.log(this.pages);
        }


    },

    mounted(){
        
        // get order id from query string
        let orderId = this.$route.query.orderId;
        // get page id from query string
        let pageId = this.$route.query.pageId;
        // get token from query string
        let ssrToken = this.$route.query.ssrToken;


        window.__ssrToken = ssrToken;

        // if pageId and token are present
        if(orderId && ssrToken && typeof pageId == 'string'){
            // get page data
            this.getPageData(orderId, pageId);
        } else if(orderId && typeof pageId == 'object'){
            this.getPagesData(orderId, pageId);
        }
    }
}
</script>