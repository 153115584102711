<template>
    <div>
        <canvas ref="mergedCanvas"></canvas>
    </div>
</template>

<script>
import { s3Client } from '../s3Client';
export default {
    name: 'Merger',
    props: {
        pages: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
        }
    },
    mounted() {
        this.mergePages()
    },
    methods: {
        async mergePages() {
            try {
                const indices = [0, 1, 2]
                const images = []
                for (const i of indices) {
                    const page = this.pages[i]
                    if (!page) continue
                    const signedUrl = await s3Client.getSignedDownloadURL({ name: page.pngfileId })
                    if(!signedUrl) continue
                    const img = await this.loadImage(signedUrl.url)
                    images.push(img)
                }
                if (!images.length) return
                const canvas = this.$refs.mergedCanvas
                const ctx = canvas.getContext('2d')
                canvas.width = images.reduce((sum, img) => sum + img.naturalWidth, 0)
                canvas.height = images[0].naturalHeight
                let offset = 0
                for (const img of images) {
                    ctx.drawImage(img, offset, 0)
                    offset += img.naturalWidth
                }

                // download the merged image
                const a = document.createElement('a')
                a.href = canvas.toDataURL('image/png')
                a.download = 'merged.png'
                a.click()
                a.remove()
            } catch (err) {
                console.error(err)
            }
        },
        loadImage(url) {
            return new Promise(resolve => {
                const img = new Image()
                img.crossOrigin = 'anonymous'
                img.onload = () => {
                    resolve(img)
                }
                img.src = url
            })
        }
    }
}
</script>